/** Composante EasterEggs de Charles */
export default class EasterEggs {
  constructor() {
    this.init();
  }
  init() {
    this.easterEgg();
    this.setTheme();
    this.themePicker();
    document.onload = this.setEasterEggClass();
  }

  easterEgg() {
    window.addEventListener(
      'keypress',
      (function () {
        var strToType = 'themes',
          strTyped = '';
        return function (event) {
          var character = String.fromCharCode(event.which);
          strTyped += character;
          if (strToType.indexOf(strTyped) === -1) strTyped = '';
          else if (strTyped === strToType) {
            strTyped = '';
            localStorage.setItem('isEasterEggUnlocked', true);
            document.documentElement.classList.add('themes');
          }
        };
      })()
    );
  }

  setEasterEggClass() {
    // set class when visitor returns
    const isEasterEggActive = localStorage.getItem('isEasterEggUnlocked');
    if (isEasterEggActive) {
      document.documentElement.classList.add('themes');
    }
  }

  setTheme() {
    const colorThemes = document.querySelectorAll('[name="theme"]');

    // store theme
    const storeTheme = function (theme) {
      localStorage.setItem('theme', theme);
    };

    // set theme when visitor returns
    const setTheme = function () {
      const activeTheme = localStorage.getItem('theme');
      colorThemes.forEach((themeOption) => {
        if (themeOption.id === activeTheme) {
          themeOption.checked = true;
        }
      });
      // fallback for no :has() support
      document.body.className = activeTheme;
    };

    colorThemes.forEach((themeOption) => {
      themeOption.addEventListener('click', () => {
        storeTheme(themeOption.id);
        // fallback for no :has() support
        document.body.className = themeOption.id;
      });
    });

    document.onload = setTheme();
  }

  themePicker() {
    const themePickerContainer = document.querySelector(
      '.theme-picker-container'
    );
    const themePickerClose = document.querySelector('.theme-picker-close');

    const themePickerButton = document.querySelector('.theme-picker-button');

    themePickerButton.addEventListener('click', (event) => {
      themePickerContainer.classList.add('active');
    });

    themePickerClose.addEventListener('click', (event) => {
      themePickerContainer.classList.remove('active');
    });
  }
}
