export default class Header {
  constructor(element) {
    this.element = element;
    this.scrollLimit = 0.1;
    this.scrollPosition = 0;
    this.lastScrollPosition = 0;
    this.html = document.documentElement;

    this.init();
    this.initNavMobile();
  }
  init() {
    console.log('header');

    window.addEventListener('scroll', this.onScroll.bind(this));
    this.onScrollBgHeader();
  }

  onScrollBgHeader() {
    var className = 'bg-active';
    var scrollTrigger = 120;

    window.onscroll = function () {
      if (window.scrollY >= scrollTrigger) {
        document.getElementsByTagName('header')[0].classList.add(className);
      } else {
        document.getElementsByTagName('header')[0].classList.remove(className);
      }
    };
  }

  initNavMobile() {
    const toggle = this.element.querySelector('.js-toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }

  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }

  onScroll(event) {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;

    this.setHeaderState();
    this.setDirectionState();
  }
  setHeaderState() {
    const scrollHeight = document.scrollingElement.scrollHeight;

    if (this.scrollPosition > scrollHeight * this.scrollLimit) {
      this.html.classList.add('header-is-hidden');
    } else {
      this.html.classList.remove('header-is-hidden');
    }
  }
  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosition) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.add('is-scrolling-up');
      this.html.classList.remove('is-scrolling-down');
    }
  }
}
