import Icons from './utils/Icons';
import ComponentFactory from './ComponentFactory';

class Main {
  constructor() {
    this.init();
  }

  init() {
    new ComponentFactory();

    Icons.load();
  }
}

new Main();
