import Swiper from 'swiper/bundle';

/** Composante Carousel de TimTools */
export default class Carousel {
  /**
   * Méthode qui crée un carousel avec l'élément HTML donné.
   * @param {HTMLElement} this.element - Variable globale de l'élément HTML reçu
   * @param {object} this.options - Options du carousel Swiper
   */
  constructor(element) {
    this.element = element;
    this.options = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
    };

    this.init();
  }
  init() {
    this.setOptions();
    new Swiper(this.element, this.options);
  }
  /**
   * Ajoute certaines options au carousel, dépendemment de quels data-attributes sont utilisés
   * @param {string} variant - description
   * @param {string} gap - Nombre de gap qui devrait avoir entre les slides du Carousel
   */
  setOptions() {
    const variant = this.element.dataset.variant;
    const gap = Number(this.element.dataset.gap);
    if (variant == 'gap') {
      this.options = {
        spaceBetween: gap,
      };
    }
    if (variant == 'actualites') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 3.5,
          spaceBetween: 20,
        },
        0: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
      };
    }
    if (variant == 'photos') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 1,
        },
      };
    }
    if (variant == 'tourisme') {
      this.options.breakpoints = {
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 2.5,
        },
        0: {
          slidesPerView: 1.5,
        },
      };
    }
    if ('autoplay' in this.element.dataset) {
      this.options.autoplay = {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      };
    }
    if ('loop' in this.element.dataset) {
      this.options.loop = true;
    }
  }
}
